import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import zoneReducer from "./slices/zone";
import fuelReducer from "./slices/fuel";
import classReducer from "./slices/class";
import smartReducer from "./slices/smart";
import modalReducer from "./slices/modal";
import modelReducer from "./slices/model";
import eventReducer from "./slices/event";
import driverReducer from "./slices/driver";
import loaderReducer from "./slices/loader";
import careerReducer from "./slices/career";
import contactReducer from "./slices/contact";
import invoiceReducer from "./slices/invoice";
import partnerReducer from "./slices/partner";
import companyReducer from "./slices/company";
import vehicleReducer from "./slices/vehicle";
import pricingReducer from "./slices/pricing";
import bookingReducer from "./slices/booking";
import paymentReducer from "./slices/payment";
import campaignReducer from "./slices/campaign";
import currencyReducer from "./slices/currency";
import timeZoneReducer from "./slices/timeZone";
import dashboardReducer from "./slices/dashboard";
import { mapReducer } from "./slices/map/mapSlice";
import formLoaderReducer from "./slices/formLoader";
import commissionReducer from "./slices/commission";
import payRollReducer from "./slices/payroll/payroll";
import carMovementReducer from "./slices/carMovement";
import paymentLinkReducer from "./slices/paymentLink";
import { roleReducer } from "./slices/role/roleSlice";
import locationPricesReducer from "./slices/locationPrices";
import fixedPricingReducer from "./slices/fixedPricing";
import notificationReducer from "./slices/notification";
import ratingReducer from "./slices/rating/ratingSlice";
import searchHistoryReducer from "./slices/searchHistory";
import companyInvoiceReducer from "./slices/companyInvoice";
import reportedProblemReducer from "./slices/reportedProblem";
import { dynamicReducer } from "./slices/dynamics/dynamicSlice";
import availabilityReducer from "./slices/availability/availabilitySlice";
import partnerRequestReducer from "./slices/partnerRequest/partnerRequestSlice";
import companyRequestReducer from "./slices/companyRequest/companyRequestSlice";
import customerInvoiceReducer from "./slices/customerInvoice/customerInvoiceSlice";
import scriptReducer from "./slices/script";

const appReducer = combineReducers({
  form: formReducer,
  script: scriptReducer,
  map: mapReducer,
  role: roleReducer,
  auth: authReducer,
  user: userReducer,
  fuel: fuelReducer,
  zone: zoneReducer,
  class: classReducer,
  modal: modalReducer,
  model: modelReducer,
  event: eventReducer,
  smart: smartReducer,
  driver: driverReducer,
  loader: loaderReducer,
  career: careerReducer,
  rating: ratingReducer,
  contact: contactReducer,
  invoice: invoiceReducer,
  partner: partnerReducer,
  company: companyReducer,
  vehicle: vehicleReducer,
  pricing: pricingReducer,
  booking: bookingReducer,
  payment: paymentReducer,
  payroll: payRollReducer,
  dynamics: dynamicReducer,
  campaign: campaignReducer,
  currency: currencyReducer,
  timeZone: timeZoneReducer,
  dashboard: dashboardReducer,
  formLoader: formLoaderReducer,
  commission: commissionReducer,
  locationPrice: locationPricesReducer,
  carMovement: carMovementReducer,
  paymentLink: paymentLinkReducer,
  availability: availabilityReducer,
  fixedPricing: fixedPricingReducer,
  notification: notificationReducer,
  searchHistory: searchHistoryReducer,
  companyRequest: companyRequestReducer,
  companyInvoice: companyInvoiceReducer,
  partnerRequest: partnerRequestReducer,
  reportedProblem: reportedProblemReducer,
  customerInvoices: customerInvoiceReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
