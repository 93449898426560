import { CompanyRequestType } from "./../redux/slices/companyRequest/index";
import { companyRequestActions } from "./../redux/slices/companyRequest/companyRequestSlice";
import http from "./http.service";
import Promisable from "./promisable.service";
import { MODAL, modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { companyActions } from "redux/slices/company";
import { formLoaderActions } from "redux/slices/formLoader";
import SubscriptionService from "./subscription.service";
import FixedPricingService from "./fixedPricing.service";

const url = "/company";
const company_requests_url = "/company-requests";

const CompanyService = {
  addCompany: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.addCompany(company));
      navigate?.(`/company-details/${company._id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateCompany: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { company, copy } = success.data.data;
      dispatch?.(companyActions.updateCompany({ id, company }));
      if (copy) {
        SubscriptionService.subscribe(() => {
          FixedPricingService.addCopyPrice(company?._id);
        });
        dispatch?.(
          modalActions.openModal({
            width: "500px",
            type: MODAL.CONFIRMATION_FORM,
            data: {
              id,
              message: "Are you sure you want to proceed with this action?",
              heading: "Copy Dynamic Pricing",
            },
          })
        );
      }
      else
        navigate?.(`/company-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getCompany: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
    } else dispatch?.(companyActions.setCompany({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getCompanyUsers: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/user/${id}`)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(companyActions.setCompanyUsers(user))
    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  addCompanyUser: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/user`, data)
    );

    if (success) {
      dispatch?.(loaderActions.setLoading(false));
      navigate?.("/companies")

    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getAllCompanies: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { companies } = success.data.data;
      dispatch?.(companyActions.setCompanies(companies));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAllCompanyCustomers: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/customers/${id}`)
    );

    if (success) {
      const { customers } = success.data.data;
      dispatch?.(companyActions.setCompanyCustomers(customers));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateCompanyStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/status/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  uploadCompanyDocs: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/upload/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateCompanyDocsStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/document/status/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  checkDocumentsExpiry: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/update-expiry`)
    );

    if (success) dispatch?.(modalActions.closeModal());

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  getCompanyRequests: async (type: CompanyRequestType) => {
    const dispatch = getAppDispatch();
    dispatch?.(companyRequestActions.setLoading({ type, loading: true }));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${company_requests_url}/${type}`)
    );

    if (success) {
      const companyRequests = success.data.data.requests;
      dispatch?.(
        companyRequestActions.setCompanyRequests({ type, companyRequests })
      );
    }

    dispatch?.(companyRequestActions.setLoading({ type, loading: false }));
    return [success, error];
  },
  deleteCompanyRequest: async (type: CompanyRequestType, id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${company_requests_url}/update-status/${id}`)
    );

    if (success) {
      const { company_request } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(
        companyRequestActions.updateCompanyRequest({
          type,
          companyRequest: company_request,
        })
      );
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  getPaymentMethods: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`/strings?title=payment_methods`)
    );

    if (success) {
      const { values } = success.data.data.string;

      dispatch?.(companyActions.setPaymentOptions(values));

    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default CompanyService;
