import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { pricingActions } from "redux/slices/fixedPricing";
import { formLoaderActions } from "redux/slices/formLoader";
import { modalActions } from "redux/slices/modal";

const url = "/fixed-price";
const urlZone = "/zone";
const urlClass = "/class";

const FixedPricingService = {
	addPricing: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(formLoaderActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}`, data)
		);

		if (success) {
			const { price: pricing } = success.data.data;
			dispatch?.(pricingActions.addPricing(pricing));
			navigate?.(`/company-details/${data.company_id}`);
		}

		dispatch?.(formLoaderActions.setLoading(false));
		return [success, error];
	},
	addCopyPrice: async (id: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(formLoaderActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/copy/${id}`,)
		);

		if (success) {
			// const { price: pricing } = success.data.data;
			// dispatch?.(pricingActions.addPricing(pricing));
			// navigate?.("/fixed-prices");
			dispatch?.(modalActions.closeModal());
		}

		dispatch?.(formLoaderActions.setLoading(false));
		return [success, error];
	},
	updatePricing: async (id: string, data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(formLoaderActions.setLoading(true));

		http.setJWT();
		http.setMultiPart();

		const [success, error]: any = await Promisable.asPromise(
			http.patch(`${url}/${id}`, data)
		);

		if (success) 
			navigate?.(`/company-details/${data.company_id}`);

		dispatch?.(formLoaderActions.setLoading(false));
		return [success, error];
	},
	getPricing: async (id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();
		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/${id}`)
		);

		if (success) {
			const { price: pricing } = success.data.data;
			dispatch?.(pricingActions.setPricing(pricing));
		} else dispatch?.(pricingActions.setPricing({ data: "Not Found" }));

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	getPricingByClass: async (class_id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/class/${class_id}`)
		);

		if (success) {
			const { price: pricing } = success.data.data;
			dispatch?.(pricingActions.setPricing(pricing));
			dispatch?.(modalActions.closeModal());
		} else dispatch?.(pricingActions.setPricing({ data: "Not Found" }));

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	getAllPricings: async (data: any) => {
		const dispatch = getAppDispatch();
		dispatch?.(pricingActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/all`, data)
		);

		if (success) {
			const { prices: pricings } = success.data.data;
			dispatch?.(pricingActions.setPricings(pricings));
		}

		dispatch?.(pricingActions.setLoading(false));
		return [success, error];
	},
	getPricingClasses: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(pricingActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${urlClass}`)
		);

		if (success) {
			const { classes } = success.data.data;
			dispatch?.(pricingActions.setPriceClasses(classes));
		}

		dispatch?.(pricingActions.setLoading(false));
		return [success, error];
	},
	getPricingAllZones: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(pricingActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${urlZone}`)
		);

		if (success) {
			const { zones } = success.data.data;
			dispatch?.(pricingActions.setPriceZones(zones));
		}

		dispatch?.(pricingActions.setLoading(false));
		return [success, error];
	},
};

export default FixedPricingService;
