import Button from "components/atoms/Button";
import { Field, reduxForm } from "redux-form";

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import RoleCheckboxRedux from "components/molecules/RoleCheckboxRedux";
import InputRedux from "components/molecules/InputRedux/InputRedux";
import { required } from "utils/validate.util";
import { useAppSelector } from "redux/hooks";

const AddRolesPermissionsForm = ({
  handleSubmit,
  handleChange,
  permissionsList,
}: any) => {
  const loading = useAppSelector((state) => state.role.loading);

  return (
    <form onSubmit={handleSubmit}>
      <>
        <Box sx={{ background: "white", p: 2, mb: 2 }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Field
                name={"role_name"}
                label={"Role Name"}
                component={InputRedux}
                validate={[required]}
              />
            </Grid>
          </Grid>
        </Box>
        <TableContainer>
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              container
              coloumns={4}
              loading={loading}
              length={permissionsList.length === 0 ? 0 : permissionsList.length}
              message="No stores available"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Feature</StyledTableCell>
                  <StyledTableCell>Read</StyledTableCell>
                  <StyledTableCell>Write</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {permissionsList.length > 0
                  ? permissionsList.map((permisions: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{permisions.name}</StyledTableCell>

                          <StyledTableCell>
                            {permisions.read === null ? null : (
                              <RoleCheckboxRedux
                                name={`read`}
                                info={permisions}
                                checked={permisions.read}
                                handleChange={handleChange}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {permisions.write === null ? null : (
                              <RoleCheckboxRedux
                                name={`write`}
                                info={permisions}
                                checked={permisions.write}
                                handleChange={handleChange}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {permisions.delete === null ? null : (
                              <RoleCheckboxRedux
                                name={`delete`}
                                info={permisions}
                                checked={permisions.delete}
                                handleChange={handleChange}
                              />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  : null}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
        </TableContainer>

        <Button color="primary" variant="contained" type="submit">
          Submit
        </Button>
      </>
    </form>
  );
};

export default reduxForm({ form: "AddRolesPermissionsForm" })(
  AddRolesPermissionsForm
);
