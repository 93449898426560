import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SelectRedux from "components/molecules/SelectRedux";
import { bookingActions } from "redux/slices/booking";


export default function SelectRole(props: any) {
  const dispatch = useAppDispatch();
  const booking = useAppSelector((state) => state.booking.booking);
  const [refundOptions, setRefundOptions] = useState<any[]>([])

  useEffect(() => {
    if (!booking) return
    let options: any[] = [];
    if (booking.user_id && booking.payment_intent !== "")
      options.push({ value: booking.payment_intent, label: `booking price-${booking.price_details.brutto_price} || refunded price-${booking.price_details.refunded_price}`, data: { trip: "original", price: booking.price_details.brutto_price, refundedPrice: booking.price_details.refunded_price } })

    if (booking.user_id && booking?.stripe_payment_detail?.payment_intent !== "")
      options.push({ value: booking?.stripe_payment_detail?.payment_intent, label: `booking price-${booking.price_details.brutto_price} || refunded price-${booking.price_details.refunded_price}`, data: { trip: "original", price: booking.price_details.brutto_price, refundedPrice: booking.price_details.refunded_price } })

    if (booking.extend_ids && booking.extend_ids.length > 0) {
      for (const extend of booking.extend_ids) {
        options.push({ value: extend.payment_intent, label: `extension price-${extend.price} || refunded price-${extend.refunded_price}`, data: { trip: "extension", extend_id: extend._id, price: extend.price, merchant_ref: extend.merchant_ref, paidBy: extend.payment_method, refundedPrice: extend.refunded_price } })
      }
    }
    dispatch?.(bookingActions.setRefundOptions(options))

    setRefundOptions(options)
  }, [booking]);

  return <SelectRedux {...props} options={refundOptions} />;
}
