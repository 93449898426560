import {
  ReportedProblemLoadingPayload,
  ReportedProblemState,
  ReportedProblemType,
  SetFiltersPayload,
  SetPagePayload,
  SetReportedProblemPayload,
  SetReportedProblemsPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {config} from 'config'
const default_page_size = config.PAGE_SIZE;


const initialReportedProblem = {
  reportedProblems: [],
  loading: true,
  filters:{ page: 1, page_size: default_page_size },
  current_filters:{},
  refreshLoader:false,
  count:0
};

const initialState: ReportedProblemState = {
  tab: 0,
	refresh: 0,
	count: 0,
  reportedProblem: null,
  loading: true,
  open: initialReportedProblem,
  close: initialReportedProblem,
  filters:{ page: 1, page_size: default_page_size },
  current_filters:{},
  refreshLoader:false
};

export const reportedProblemSlice = createSlice({
  name: "reportedProblem",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const {page,type}=action.payload
      state.refresh += 1;
      state[type].refreshLoader = true;
      state[type].filters.page =page;
    },
    resetPage: (state, action: PayloadAction<ReportedProblemType>) => {
			const type = action.payload;
			state[type].filters.page = 1;
		},
		setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
			const { type, data } = action.payload;
			state[type].filters = data;
			state.refreshLoader = true;
      
		},
		resetFilters: (state, action: PayloadAction<ReportedProblemType>) => {
			const type = action.payload;
			state.refresh += 1;
			state.refreshLoader = true;
			state[type].filters = initialState.filters;
			state[type].current_filters = initialState.current_filters;
		},
    setCurrentFilters: (	state,action: PayloadAction<SetFiltersPayload>) => {
			const { type, data } = action.payload;
			state[type].current_filters = data;
		},
    setLoading: (state,action: PayloadAction<ReportedProblemLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setReportedProblems: (state,action: PayloadAction<SetReportedProblemsPayload>) => {
      const { type, reportedProblems,count } = action.payload;
      state[type].reportedProblems = reportedProblems;
      state[type].count=count
      state[type].refreshLoader = false;

    },
    updateReportedProblems: (state,action: PayloadAction<SetReportedProblemPayload>) => {
      const { type, reportedProblem } = action.payload;

      if (!reportedProblem?._id) return;

      state[type].reportedProblems = state[type].reportedProblems.filter(
        ({ _id }) => _id !== reportedProblem._id
      );
    },
  },
});

const reportedProblemReducer = reportedProblemSlice.reducer;

export const reportedProblemActions = reportedProblemSlice.actions;
export default reportedProblemReducer;
