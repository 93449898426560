import AddInvoice from "pages/AddInvoice";
import AddRole from "pages/AddRole";
import AddRolesPermissions from "pages/AddRolesPermissions/AddRolesPermissions";
import Availability from "pages/Availability";
import CustomerInvoices from "pages/CustomerInvoices";
import Dynamics from "pages/Dynamics";
import AddUser from "pages/UserDetails/AddUser";
import { lazy } from "react";

const AddZone = lazy(() => import("pages/AddZone"));
const AddClass = lazy(() => import("pages/AddClass"));
const AddModel = lazy(() => import("pages/AddModel"));
const AddDriver = lazy(() => import("pages/AddDriver"));
const AddBooking = lazy(() => import("pages/AddBooking"));
const AddVehicle = lazy(() => import("pages/AddVehicle"));
const AddPartner = lazy(() => import("pages/AddPartner"));
const AddCompany = lazy(() => import("pages/AddCompany"));
const AddPricing = lazy(() => import("pages/AddPricing"));
const AddCampaign = lazy(() => import("pages/AddCampaign"));
const AddCarMovement = lazy(() => import("pages/AddCarMovement"));
const AddPaymentLink = lazy(() => import("pages/AddPaymentLink"));
const AddLocationPrice = lazy(() => import("pages/AddLocationPrice"));
const AddFixedPricing = lazy(() => import("pages/AddFixedPricing"));
const SendPushNotification = lazy(() => import("pages/SendPushNotification"));
// const AddCareer = lazy(() => import("pages/AddCareer"));

const UserDetails = lazy(() => import("pages/UserDetails"));
const DriverDetails = lazy(() => import("pages/DriverDetails"));
const EventsDetails = lazy(() => import("pages/EventsDetails"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));
const VehicleDetails = lazy(() => import("pages/VehicleDetails"));
const PartnerDetails = lazy(() => import("pages/PartnerDetails"));
const CompanyDetails = lazy(() => import("pages/CompanyDetails"));

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Zone = lazy(() => import("pages/Zone"));
const Users = lazy(() => import("pages/Users"));
const Fuels = lazy(() => import("pages/Fuels"));
const Roles = lazy(() => import("pages/Roles"));
const Events = lazy(() => import("pages/Events"));
const Models = lazy(() => import("pages/Models"));
const Pricing = lazy(() => import("pages/Pricing"));
const Drivers = lazy(() => import("pages/Drivers"));
const Contacts = lazy(() => import("pages/Contacts"));
const Partners = lazy(() => import("pages/Partners"));
const Vehicles = lazy(() => import("pages/Vehicles"));
const Invoices = lazy(() => import("pages/Invoices"));
const Bookings = lazy(() => import("pages/Bookings"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Campaigns = lazy(() => import("pages/Campaigns"));
const Companies = lazy(() => import("pages/Companies"));
const Commission = lazy(() => import("pages/Commission"));
const CarMovement = lazy(() => import("pages/CarMovement"));
const UsersPoints = lazy(() => import("pages/UsersPoints"));
const LocationPrices = lazy(() => import("pages/LocationPrices"));
const FixedPricing = lazy(() => import("pages/FixedPricing"));
const ServiceClass = lazy(() => import("pages/ServiceClass"));
const PaymentLinks = lazy(() => import("pages/PaymentLinks"));
const Notifications = lazy(() => import("pages/Notifications"));
const SearchHistory = lazy(() => import("pages/SearchHistory"));
const AddComapnyUser = lazy(() => import("pages/AddComapnyUser"));
const BookingRatings = lazy(() => import("pages/BookingRatings"));
const CompanyInvoices = lazy(() => import("pages/CompanyInvoices"));
const CompanyRequests = lazy(() => import("pages/CompanyRequests"));
const CampaignDetails = lazy(() => import("pages/CampaignDetails"));
const PartnerRequests = lazy(() => import("pages/PartnerRequests"));
const ReportedProblems = lazy(() => import("pages/ReportedProblems"));
const AddScript = lazy(() => import("pages/AddScript"));

// const Careers = lazy(() => import("pages/Careers"));
// const Applicants = lazy(() => import("pages/Applicants"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/bookings", element: <Bookings /> },
  { path: "/add-booking", element: <AddBooking /> },
  { path: "/update-booking/:id", element: <AddBooking /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },
  { path: "/add-script", element: <AddScript /> },
  { path: "/update-script/:id", element: <AddScript /> },

  { path: "/car-movement", element: <CarMovement /> },
  { path: "/add-car-movement", element: <AddCarMovement /> },
  { path: "/update-car-movement/:id", element: <AddCarMovement /> },

  { path: "/dashboard", element: <Dashboard /> },

  { path: "/users", element: <Users /> },
  { path: "/add-user", element: <AddUser /> },
  { path: "/user-points/:id", element: <UsersPoints /> },
  { path: "/user-details/:id", element: <UserDetails /> },

  { path: "/chauffeurs", element: <Drivers /> },
  { path: "/add-chauffeur", element: <AddDriver /> },
  { path: "/update-chauffeur/:id", element: <AddDriver /> },
  { path: "/chauffeur-details/:id", element: <DriverDetails /> },

  { path: "/vehicles", element: <Vehicles /> },
  { path: "/add-vehicle", element: <AddVehicle /> },
  { path: "/update-vehicle/:id", element: <AddVehicle /> },
  { path: "/vehicle-details/:id", element: <VehicleDetails /> },

  { path: "/models", element: <Models /> },
  { path: "/add-model", element: <AddModel /> },
  { path: "/update-model/:id", element: <AddModel /> },

  { path: "/class", element: <ServiceClass /> },
  { path: "/add-class", element: <AddClass /> },
  { path: "/update-class/:id", element: <AddClass /> },
  { path: "/availability", element: <Availability /> },

  { path: "/partners", element: <Partners /> },
  { path: "/add-partner", element: <AddPartner /> },
  { path: "/update-partner/:id", element: <AddPartner /> },
  { path: "/partner-details/:id", element: <PartnerDetails /> },

  { path: "/partner-requests", element: <PartnerRequests /> },

  { path: "/companies", element: <Companies /> },
  { path: "/add-company", element: <AddCompany /> },
  { path: "/update-company/:id", element: <AddCompany /> },
  { path: "/company-details/:id", element: <CompanyDetails /> },

  { path: "/company-requests", element: <CompanyRequests /> },

  { path: "/campaigns", element: <Campaigns /> },
  { path: "/add-campaign", element: <AddCampaign /> },
  { path: "/update-campaign/:id", element: <AddCampaign /> },
  { path: "/campaign-details/:id", element: <CampaignDetails /> },

  { path: "/pricing", element: <Pricing /> },
  { path: "/add-pricing", element: <AddPricing /> },
  { path: "/update-pricing/:id", element: <AddPricing /> },

  { path: "/partner-invoices", element: <Invoices /> },
  { path: "/company-invoices", element: <CompanyInvoices /> },

  // { path: "/careers", element: <Careers /> },
  // { path: "/add-career", element: <AddCareer /> },
  // { path: "/update-career/:id", element: <AddCareer /> },
  // { path: "/careers/:id", element: <Applicants /> },

  { path: "/events", element: <Events /> },
  { path: "/event-details/:id", element: <EventsDetails /> },

  // { path: "/payroll", element: <PayRoll /> },
  { path: "/contacts", element: <Contacts /> },
  { path: "/booking-ratings", element: <BookingRatings /> },
  { path: "/search-history", element: <SearchHistory /> },
  { path: "/reported-problems", element: <ReportedProblems /> },

  { path: "/dynamics", element: <Dynamics /> },
  { path: "/customer-invoices", element: <CustomerInvoices /> },
  { path: "/add-invoices", element: <AddInvoice /> },

  // { path: "/location", element: <CustomLocation /> },

  { path: "/roles", element: <Roles /> },
  { path: "/add-role", element: <AddRole /> },
  { path: "/update-role/:id", element: <AddRole /> },
  { path: "/add-role-permission", element: <AddRolesPermissions /> },
  { path: "/update-role-permission/:id", element: <AddRolesPermissions /> },

  { path: "/add-company-user", element: <AddComapnyUser /> },

  { path: "/Zone", element: <Zone /> },
  { path: "/add-zone", element: <AddZone /> },
  { path: "/update-zone/:id", element: <AddZone /> },

  { path: "/Notifications", element: <Notifications /> },
  { path: "/send-push-notification", element: <SendPushNotification /> },
  { path: "/update-push-notification/:id", element: <SendPushNotification /> },

  { path: "/commission", element: <Commission /> },

  { path: "/fuels", element: <Fuels /> },

  { path: "/payment-link", element: <PaymentLinks /> },
  { path: "/add-payment-link", element: <AddPaymentLink /> },
  { path: "/update-payment-link/:id", element: <AddPaymentLink /> },

  { path: "/location-prices", element: <LocationPrices /> },
  { path: "/add-location-price", element: <AddLocationPrice /> },
  { path: "/update-location-price/:id", element: <AddLocationPrice /> },

  // { path: "/fixed-prices", element: <FixedPricing /> },
  { path: "/add-fixed-price", element: <AddFixedPricing /> },
  { path: "/update-fixed-price/:id", element: <AddFixedPricing /> },
];
