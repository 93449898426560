export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  ADD_NOTES = "ADD_NOTES",
  PAY_INVOICE = "PAY_INVOICE",
  DELETE_USER = "DELETE_USER",
  ADD_PAYMENT = "ADD_PAYMENT",
  HANDLE_STATUS = "HANDLE_STATUS",
  CHILDREN_SEATS = "CHILDREN_SEATS",
  ASSIGN_BOOKING = "ASSIGN_BOOKING",
  DISABLE_DRIVER = "DISABLE_DRIVER",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  MARKET_TRANSFER = "MARKET_TRANSFER",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  EXPORT_TABLE_OPTIONS = "EXPORT_TABLE_OPTIONS",
  UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS",
  UPDATE_BOOKING_BILLING_ADDRESS = "UPDATE_BOOKING_BILLING_ADDRESS",
  UPDATE_PAYROLL = "UPDATE_PAYROLL",
  UPDATE_DYNAMICS = "UPDATE_DYNAMICS",
  UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS",
  ADD_ATTACHMENT = "ADD_ATTACHMENT",
  EDIT_ADDRESS = "EDIT_ADDRESS",
  EDIT_ADDRESS1 = "EDIT_ADDRESS1",
  REFUND = "REFUND",
  CONFIRM_REFUND = "CONFIRM_REFUND",
  SEARCH_HISTORY_STATS = "SEARCH_HISTORY_STATS",
  ASSIGN_PRICE = "ASSIGN_PRICE",
  UPDATE_CROSS_COUNTRY_PRICE = "UPDATE_CROSS_COUNTRY_PRICE",
  BOOKING_DETAILS = "BOOKING_DETAILS",
  Add_SHIFT = "Add_SHIFT",
  DYNAMIC_CAR_MOVEMENT = "DYNAMIC_CAR_MOVEMENT",
  FINISH_CAR_MOVEMENT = "FINISH_CAR_MOVEMENT",
  INDIVIDUAL_DISCOUNT = "INDIVIDUAL_DISCOUNT",
  SET_SMART_SCRIPT = "SET_SMART_SCRIPT",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
  closeBackdropClick?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
