import PinImg from "assets/pin1.png";
import PinImgEnd from "assets/pin.png";
import { mapAction } from "redux/slices/map/mapSlice";
import { getAppDispatch } from "utils/dispatch.util";
import { config } from "config";
import http from "./http.service";
import Promisable from "./promisable.service";
import { zoneActions } from "redux/slices/zone";

const url = "/zone";
const mapOptions = {
  zoom: 12,
  // mapTypeControl: false,
  clickableIcons: false,
  // gestureHandling: "none",
  // disableDefaultUI: true,
  // keyboardShortcuts: false,
  backgroundColor: "#f8f8f8",
  center: { lat: -33.8688, lng: 151.2195 },
};

const MapService = {
  calculateDistance: async function (
    originPlaceId: any,
    destinationPlaceId: any,
    coordinates: any,
    pickupCoordinates: any,
    destinationCoordinates: any
  ) {
    if (!originPlaceId || !coordinates) return false;
    const dispatch = getAppDispatch();
    return new Promise(async (resolve) => {
      http.setJWT();

      const [success, error]: any = await Promisable.asPromise(
        http.post(`${url}/coordinates`, {
          origin: originPlaceId,
          destination: destinationPlaceId == "" ? "" : destinationPlaceId,
          coordinates: coordinates,
          pickupCoordinates,
          destinationCoordinates,
        })
      );

      if (success) {
        const { distance, zone, duration } = success.data.data;
        if (distance) {
          resolve({
            distance: distance,
            duration: duration ? (Number(duration) / 60)?.toFixed(2) : 0,
            zone: zone,
          });
        } else {
          resolve({ zone: zone });
        }
        dispatch?.(zoneActions.setZone(zone));
        return;
      }
      // ToasterService.showError("Route Not Found");
      resolve(null);
    });
  },
  // get calculateDistance() {
  //   return this._calculateDistance;
  // },
  // set calculateDistance(value) {
  //   this._calculateDistance = value;
  // },

  calculateDirections: async function (locations: any[]) {
    return new Promise((resolve) => {
      let google = (window as any).google;

      if (!google) return resolve(null);
      if (locations.length < 2) return resolve(null);

      let directionsService = new google.maps.DirectionsService();

      let waypoints: any = [];
      let originLatLng = locations[0].location;
      let destinationLatLng = locations[locations.length - 1].location;
      let length = locations.length > 10 ? 10 : locations.length;

      for (var i = 1; i < length - 1; i++) {
        waypoints.push({ location: locations[i] });
      }

      let request: any = {
        origin: new google.maps.LatLng(originLatLng),
        destination: new google.maps.LatLng(destinationLatLng),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      };

      if (waypoints.length > 0) request = { ...request, waypoints };

      directionsService.route(request, (response: any, status: any) => {
        if (status === "OK") return resolve(response);
        else window.alert("Directions request failed due to " + status);

        resolve(null);
      });
    });
  },

  renderDirections: (
    response: any,
    map_id: string,
    waypoints: any[],
    options?: any
  ) => {
    let google = (window as any).google;

    if (!google) return;
    if (!response) return;

    const map = new google.maps.Map(document.getElementById(map_id), {
      ...mapOptions,
      ...options,
    });

    let directionsRenderer = new google.maps.DirectionsRenderer({
      map,
      directions: response,
      suppressMarkers: true,
    });

    directionsRenderer.setOptions({
      polylineOptions: {
        strokeColor: "#1f2c34",
      },
    });

    const legs = response.routes[0].legs;

    legs.forEach((leg: any, i: number) => {
      const start_marker = new google.maps.Marker({
        position: leg.start_location,
        map,
        icon: PinImg,
      });

      const start_infowindow = new google.maps.InfoWindow({
        maxWidth: 230,
        content: `
          <div>
            <span style="font-weight: bold; text-transform: capitalize;">
              ${waypoints[i].status}
            </span>: ${leg.start_address}
          </div>
        `,
      });

      start_infowindow.open({
        anchor: start_marker,
        map,
        shouldFocus: false,
      });

      start_marker.addListener("click", () => {
        start_infowindow.open({
          anchor: start_marker,
          map,
          shouldFocus: false,
        });
      });

      if (i === legs.length - 1) {
        const end_marker = new google.maps.Marker({
          position: leg.end_location,
          map,
          icon: PinImgEnd,
        });
        const end_infowindow = new google.maps.InfoWindow({
          maxWidth: 230,
          content: `
          <div>
            <span style="font-weight: bold; text-transform: capitalize;">
              ${waypoints[i + 1].status}
            </span>: ${leg.end_address}
          </div>
          `,
        });
        end_infowindow.open({
          anchor: end_marker,
          map,
          shouldFocus: false,
        });
        end_marker.addListener("click", () => {
          end_infowindow.open({
            anchor: end_marker,
            map,
            shouldFocus: false,
          });
        });
      }
    });
  },

  renderMap: (
    title: any,
    address: any,
    location: any,
    map_id: string,
    options?: any
  ) => {
    let google = (window as any).google;
    if (!google) return;

    const map = new google.maps.Map(document.getElementById(map_id), {
      ...mapOptions,
      zoom: 16,
      center: location,
      ...options,
    });

    const marker = new google.maps.Marker({
      position: location,
      map,
      title: address,
      icon: PinImg,
    });

    const infowindow = new google.maps.InfoWindow({
      content: title,
    });

    infowindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });
  },

  getLocation: async (lat: any, lng: any, pick?: any) => {
    const dispatch = getAppDispatch();

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.GOOGLE_MAPS_API_KEY}`
    );
    var data = await response.json();

    pick === true && dispatch?.(mapAction.setStartFormatted(data.results[0]));
    pick === false && dispatch?.(mapAction.setEndFormatted(data.results[0]));
  },

  polygon: (
    map_id: string,
    coordinates: any,
    listedCoordinates: any,
    otherCoordinates: any,
    get_lng_latFrom_map: any,
    zone: number
  ) => {
    let google = (window as any).google;

    if (!google) return;
    const map = new google.maps.Map(document.getElementById(map_id), {
      zoom: zone,
      center: coordinates || { lat: 24.886, lng: -70.268 },
      mapTypeId: "roadmap",
    });

    // Define the LatLng coordinates for the polygon's path.
    const newMeter = (3000 * 0.1) / 10000;
    const lat1 = coordinates.lat - newMeter;
    const lat2 = coordinates.lat + newMeter;
    const lon1 = coordinates.lng - newMeter;
    const lon2 = coordinates.lng + newMeter;

    const newLatLng = [
      { lat: lat1, lng: lon1 },
      { lat: lat2, lng: lon2 },
    ];

    // Construct the polygon.
    const polygonMap = new google.maps.Polygon({
      paths: listedCoordinates === "" ? newLatLng : listedCoordinates,
      strokeColor: "#520A76",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#520A76",
      fillOpacity: 0.35,
      editable: true,
      draggable: false,
    });

    polygonMap.setMap(map);
    if (otherCoordinates !== "" && otherCoordinates) {
      for (const coor of otherCoordinates) {
        const ottherpolygonMap = new google.maps.Polygon({
          paths: coor.coordinates,
          strokeColor: coor.isActive ? "#000000" : "#808080",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: coor.isActive ? "#000000" : "#808080",
          fillOpacity: 0.35,
          editable: false,
          draggable: false,
        });
        ottherpolygonMap.setMap(map);
      }
    }

    polygonMap.getPaths().forEach(function (path: any, index: number) {
      google.maps.event.addListener(path, "insert_at", function () {
        var data = getPathArray(polygonMap);
        var data1 = getPathArray_for_db(polygonMap);

        get_lng_latFrom_map(data, data1);
      });

      google.maps.event.addListener(path, "remove_at", function () {
        var data = getPathArray(polygonMap);
        var data1 = getPathArray_for_db(polygonMap);
        get_lng_latFrom_map(data, data1);
      });

      google.maps.event.addListener(path, "set_at", function () {
        var data = getPathArray(polygonMap);
        var data1 = getPathArray_for_db(polygonMap);
        get_lng_latFrom_map(data, data1);
      });
    });
    function getPathArray(polygon: any) {
      return polygon
        .getPath()
        .getArray()
        .map((p: any) => {
          return { lat: p.lat(), lng: p.lng() };
        });
    }
    function getPathArray_for_db(polygon: any) {
      return polygon
        .getPath()
        .getArray()
        .map((p: any) => {
          return [p.lng(), p.lat()];
        });
    }
  },
  getAddressFromLatLng: async (lat: any, lng: any) => {
    var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    var address;

    var request = {
      location: latlng,
    };

    return new Promise((resolve, reject) => {
      geocoder.geocode(request, function (results: any, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            address = results[0];
          } else {
            address = "No results found";
          }
        } else {
          address = "Geocoder failed due to: " + status;
        }
        resolve(address);
      });
    });
  },
};

export default MapService;
