import { roleState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: roleState = {
  roles: [],
  permissions:[],
  privilege:[],
  loading: false,
    roleOptions: [],
    role:null
};

function replaceAndCapitalize(str:any) {
  var arr = str.split("_");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
      },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
      },
      setRole: (state, action) => {
          state.role=action.payload
      },
      setPrivilege: (state, action) => {
          state.privilege=action.payload
      },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRoleOptions: (state, action) => {
          const roles = action.payload;
          let options: SelectOption[] = [];
      
      roles.forEach(({ title,_id }: any) => {
        
             options.push({ value: _id, label: replaceAndCapitalize(title)});}
            //  options.push({ value: title, label: replaceAndCapitalize(title)});}
          );
          // state.roles = roles;
          state.roleOptions = options;
    },
  },
});

export const roleReducer = roleSlice.reducer;

export const roleActions = roleSlice.actions;
