import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { BookingType } from "redux/slices/booking";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";
import { bookingActions } from "redux/slices/booking";
import { formLoaderActions } from "redux/slices/formLoader";
import ObjectService from "utils/object.util";
import DateService from "utils/date.util";
import { mapAction } from "redux/slices/map/mapSlice";
import ToasterService from "utils/toaster.util";
import { PriceService } from "utils/price_calculation";
import { AssignmentReturnedTwoTone } from "@mui/icons-material";
import { digitFixer } from "utils/digitfixer.util";

const url = "/trip";

const BookingService = {
  addBooking: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.newBooking({ type: "admin", booking: trip }));
      navigate?.(`/booking-details/${trip._id}`);
      dispatch?.(mapAction.setStartFormatted([]));
      dispatch?.(mapAction.setEndFormatted([]));
    }

    return [success, error];
  },

  addDuplicateBooking: async (data: any, navigate?: NavigateFunction) => {
    
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/duplicate`, data)
    );

    if (success) {
      navigate?.(`/bookings?tab=1`);
    }

    return [success, error];
  },

  updateBooking: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
      navigate?.(`/booking-details/${id}`);
      dispatch?.(mapAction.setStartFormatted([]));
      dispatch?.(mapAction.setEndFormatted([]));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getBooking: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      const { message } = success.data;
      if (message !== "") {
        if (message.includes("rejected")) {
          ToasterService.showError(message);
        } else {
          ToasterService.showSuccess(message);
        }
      }
      dispatch?.(bookingActions.setBooking(trip));
    } else dispatch?.(bookingActions.setBooking({ data: "Not Found" }));

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },

  getBookings: async (type: BookingType, data: any, query: string) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(bookingActions.setLoading({ type, loading: true }));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${type}${query}`, data)
    );

    if (success) {
      const { trips, count } = success.data.data;

      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: trips.map((booking: any) => ({
              "Booking ID": booking.booking_number,
              "Added by ( Admin/Company/Customer)": booking.added_by,
              "Created date": DateService.getFormattedDateTimeForExcel(
                booking.createdAt
              ),
              "Created Time": DateService.getTimeString(
                booking.createdAt
              ),
              // "Created date": DateService.getFormattedDateTime(
              //   booking.createdAt
              // ),
              Pickup: booking.pickup.address,
              "Destination/Hours": booking.destination.address
                ? booking.destination.address
                : booking.duration,
              "Booking Date": DateService.getFormattedDateTimeForExcel(booking.date),
              Time: DateService.getTimeString(booking.date),
              "Trip Type": booking.trip_type,
              Class: booking.class_name,
              "Start time": DateService.getFormattedDateTime(
                booking.ride_start_time
              ),
              "End time": DateService.getFormattedDateTime(
                booking.ride_end_time
              ),
              "Payment Status": booking.payment_status,
              "Booking Status ": booking.current_status,
              "Net amount before discount":
                booking.discount_info?.discount_value > 0
                  ? parseFloat(booking.price_details.netto_price) +
                  parseFloat(booking.price_details.admin_discount)
                  : parseFloat(booking.price_details.netto_price),
              "Gross amount before discount": parseFloat(booking.price_details.total_price),
              "Discount percentage":
                booking.discount_info?.discount_type === "percentage"
                  ? booking.discount_info?.discount_value
                  : 0,
              "Discounted amount": booking.price_details.coupon.value > 0 ?
                booking.price_details.coupon.value :
                booking.discount_info?.discount_type === "percentage"
                  ? booking.price_details.admin_discount
                  : parseFloat(booking.discount_info?.discount_value),
              "Net amount after discount ": parseFloat(PriceService.get_netto_from_brutto(booking.price_details, 15).toFixed(2)),
              "Tax Amount 15%": parseFloat(PriceService.brutto_exclude_tax(booking.price_details.brutto_price, 15).toFixed(2)),
              "Gross amount after discount ":
                parseFloat(booking.price_details.brutto_price),
              "Amount paid":
                booking.payment_status === "paid"
                  ? parseFloat(booking.price_details.brutto_price)
                  : "",
              "Refund amount ": parseFloat(booking.price_details.refund.reduce((acc: any, obj: any) => acc + obj.amount, 0)?.toFixed(2)),
              "Company Name": booking.company_details?.company_name,
              "First name": booking.booking_for_someone
                ? booking.booking_for_details.first_name
                : booking.user_details.first_name,
              "Last name": booking.booking_for_someone
                ? booking.booking_for_details.last_name
                : booking.user_details.last_name,
              Phone: booking.booking_for_someone
                ? booking.booking_for_details?.phone
                : booking.user_details?.phone,
              "Passport/ID": booking?.passport,
              "Notes from admin":
                booking.added_by === "admin"
                  ? booking.additional_info.notes
                  : "",
              "Private notes from admin": booking?.private_notes?.message,
              "Notes from customer/company":
                booking.added_by !== "admin"
                  ? booking.additional_info.notes
                  : "",
              "Performed by ( Partner name )":
                booking.partner_details?.company_name,
              "Driver Name": `${ObjectService.getFullName(
                booking.driver_details
              )}`,
              "Driver Phone": booking.driver_details?.phone,
              Plate: booking.vehicle_details?.license_plate,
              City: booking?.city_name,
              "Payment Reference /Payfort ID": booking.payment_intent,
              "Free Cancellation": booking.current_status === "cancelled" ? booking.isFreeCancel ? "Free" : "Chargable" : "",
            })),
          })
        );
      else
        dispatch?.(
          bookingActions.setBookings({ type, count, bookings: trips })
        );
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(bookingActions.setLoading({ type, loading: false }));

    return [success, error];
  },
  getBookingExport: async (type: BookingType, data: any, query: string) => {
    const dispatch = getAppDispatch();

    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/allExport?type=${type}`, data)
    );

    if (success) {
      const { trips, count } = success.data.data;

      dispatch?.(
        modalActions.updateData({
          table_data: trips.map((booking: any) => {
            let price: any = 0;

            if (!booking?.source || booking?.source !== "BLACKLANE") {
              price = booking.price_details.brutto_price;
              let brutto = booking.price_details.brutto_price;
              let extras = booking.price_details.extras_dn.length > 0 ? booking.price_details.extras_dn.reduce((acc: any, obj: any) => acc + obj.total_gross, 0) : 0;
              let refund = booking.price_details.refund.length > 0 ? booking.price_details.refund.reduce((acc: any, obj: any) => acc + obj.amount, 0) : 0;
              price = digitFixer(brutto + extras - refund);
            }
            return ({
              "Booking ID": booking.booking_number,
              "Added by ( Admin/Company/Customer)": booking?.added_by || "",
              "Created date": DateService.getFormattedDateTimeForExcel(booking.createdAt),
              "Created Time": DateService.getTimeString(booking.createdAt),
              Pickup: booking.pickup.address,
              "Destination/Hours": booking.destination.address
                ? booking.destination.address
                : booking.duration,
              "Number of Extra KM": booking.extra_km,
              "Number of  Extra Hours": booking.extra_hour,
              "Booking Date": DateService.getFormattedDateTimeForExcel(booking.date),
              Time: DateService.getTimeString(booking.date),
              "Trip Type": booking.trip_type,
              Class: booking.class_name,
              "Start time": booking?.ride_start_time ? DateService.getFormattedDateTime(booking.ride_start_time) : "",
              "End time": booking?.ride_end_time ? DateService.getFormattedDateTime(booking.ride_end_time) : "",
              "Payment Status": booking.payment_status || "",
              "Booking Status ": booking.current_status || "",
              "Original Booking Net amount before discount":
                booking?.discount_info?.discount_value > 0
                  ? parseFloat(booking.price_details.netto_price) +
                  parseFloat(booking.price_details.admin_discount)
                  : parseFloat(booking?.price_details?.netto_price || 0),
              "Gross amount before discount": parseFloat(booking.price_details?.total_price || 0),
              "Discount percentage":
                booking?.discount_info?.discount_type === "percentage"
                  ? booking.discount_info?.discount_value
                  : 0,
              "Discounted amount": booking?.price_details?.coupon.value > 0 ?
                booking.price_details.coupon.value :
                booking?.discount_info?.discount_type === "percentage"
                  ? booking.price_details.admin_discount
                  : parseFloat(booking.discount_info?.discount_value || 0),
              "Net amount after discount ": booking?.price_details ? parseFloat(PriceService.get_netto_from_brutto(booking.price_details, 15).toFixed(2)) : 0,
              "Tax Amount 15%": booking?.price_details ? parseFloat(PriceService.brutto_exclude_tax(booking.price_details.brutto_price, 15).toFixed(2)) : 0,
              "Gross amount after discount ": booking?.price_details?.brutto_price ? parseFloat(booking.price_details.brutto_price) : 0,
              "Net amount for Extra Hours": booking?.source === "BLACKLANE" ? 0 : booking.price_details.extras_dn.reduce((acc: any, obj: any) => acc + obj.hour_net, 0),
              "Net Amount for Extra KM": booking?.source === "BLACKLANE" ? 0 : booking.price_details.extras_dn.reduce((acc: any, obj: any) => acc + obj.km_net, 0),
              "Amount paid": booking?.source === "BLACKLANE" ? parseFloat(booking.price) : booking?.payment_status === "paid" ? parseFloat(price) : "",
              "Refund amount ": booking?.price_details?.refund ? parseFloat(booking.price_details.refund.reduce((acc: any, obj: any) => acc + obj.amount, 0)?.toFixed(2)) : 0,
              "Company Name": booking?.company_details?.company_name || "",
              "First name": booking?.booking_for_someone
                ? booking.booking_for_details.first_name
                : booking.user_details.first_name,
              "Last name": booking.booking_for_someone
                ? booking.booking_for_details.last_name
                : booking.user_details.last_name,
              Phone: booking.booking_for_someone
                ? booking.booking_for_details?.phone
                : booking.user_details?.phone,
              "Passport/ID": booking?.passport || "",
              "Notes from admin":
                booking?.added_by === "admin"
                  ? booking.additional_info.notes
                  : "",
              "Private notes from admin": booking?.private_notes?.message || "",
              "Notes from customer/company":
                booking?.added_by !== "admin"
                  ? (booking?.additional_info?.notes || "")
                  : "",
              "Performed by ( Partner name )": booking?.partner_details?.company_name || "",
              "Driver Name": `${ObjectService.getFullName(booking.driver_details)}`,
              "Driver Phone": booking.driver_details?.phone || booking.driver_details?.mobile,
              Plate: booking?.vehicle_details?.license_plate || "",
              City: booking?.city_name,
              "Payment Reference /Payfort ID": booking?.payment_intent || "",
              "Free Cancellation": booking.current_status === "cancelled" ? booking.isFreeCancel ? "Free" : "Chargable" : "",
              "Source": booking?.source === "BLACKLANE" ? "BLACKLANE" : "YELOLIMO"
            })
          }),
        })
      );

    }

    dispatch?.(modalActions.setLoading(false));
    // if (data?.all) 
    // else dispatch?.(bookingActions.setLoading({ type, loading: false }));

    return [success, error];
  },

  getBookingsRatings: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/ratings`, { page: 1, page_size: 30 })
    );

    if (success) {
      const { trips } = success.data.data;
      dispatch?.(bookingActions.setBookingsRatings(trips));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBillingAddress: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updatebillingaddress/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;

      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  underReviewBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/review/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  underReviewSkipBooking: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/review-skip/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  rebateBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/rebate/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  AddPaymentBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/add-payment/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  AddExtensionBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/extend/${id}`, data)
    );

    if (success) 
      BookingService.getBooking(id)
    

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  sendToMarket: async (id: string, type: BookingType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/sendtomarket/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.sendMarket({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  acceptFromMarket: async (id: string, type: BookingType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/acceptfrommarket/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.acceptMarket({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  assignBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/assign/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  unassignBooking: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/unassign/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  cancelBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/cancel/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  refundBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/refund/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBookingStatus: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update-status/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  addAdminNotes: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/note/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    return [success, error];
  },
  generateInvoice: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/generate-invoice/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },
  getSearchBookings: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading({ type: "search", loading: true }));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/search`, data)
    );

    if (success) {
      const { bookings } = success.data.data;
      const count = bookings.length || 0
      dispatch?.(bookingActions.setSearchBookings({ type: "search", count, bookings }));
    }
    else dispatch?.(bookingActions.setBooking({ data: "Not Found" }));

    dispatch?.(bookingActions.setLoading({ type: "search", loading: false }));

    return [success, error];
  },
  getTripTabs: async () => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/trip-tabs`)
    );

    if (success) {
      const { bookings } = success.data.data;
      dispatch?.(bookingActions.setBookingCountTabs(bookings));

    }

    return [success, error];
  },
};

export default BookingService;
