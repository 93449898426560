import "./Header.css";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import logo from "assets/long_logo.png";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import useEffectOnce from "hooks/useEffectOnce";
import BasicMenu from "components/atoms/BasicMenu";
// import SocketService from "services/socket.service";
import OneSignalService from "services/onesignal.service";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import BlockRender from "components/organisms/BlockRender";
import { invoiceList, pricingList, userDropDownList } from ".";


export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const routes = useAppSelector((state) => state.auth?.user?.role_id?.routes);

  const [userDropDown, setuserDropDown] = useState<any[]>([])
  const [invoiceDropDown, setInvoiceDropDown] = useState<any>([])
  const [priceDropDown, setPriceDropDown] = useState<any>([])

  useEffectOnce(() => {
    if (!user) return;

    OneSignalService.connect(user._id);
    // SocketService.connect(user);
  });

  useEffect(() => {
    if (typeof routes === "object") {
      let private_user_routesCheck = userDropDownList.filter((item: any) => routes.includes(item.to));
      let private_invoice_routesCheck = invoiceList.filter((item: any) => routes.includes(item.to));
      let private_price_routesCheck = pricingList.filter((item: any) => routes.includes(item.to));
      private_user_routesCheck.push({ text: "Logout", to: "/logout" })
      setuserDropDown(private_user_routesCheck);
      setInvoiceDropDown(private_invoice_routesCheck);
      setPriceDropDown(private_price_routesCheck);

    }

  }, [routes])

  if (!user || pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar">
        <Grid container alignItems="center">
          <Grid item xs={12} lg={2}>
            <Link to="/bookings">
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  loading="lazy"
                  width={"170px"}
                  height={"100%"}
                />
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} lg={10}>
            <ul>
              <li className="ILinks">
                <BlockRender roles={"/dashboard"}>

                  <NavLink to="/dashboard">Dashboard</NavLink>
                </BlockRender>
                <BlockRender roles={"/bookings"}>

                  <NavLink to="/bookings">Bookings</NavLink>
                </BlockRender>
                <BlockRender roles={"/car-movement"}>
                  <NavLink to="/car-movement">Car Movement</NavLink>
                </BlockRender>

                <BlockRender roles={"/users"}>
                  <NavLink to="/users">Users</NavLink>
                </BlockRender>

                <BlockRender roles={"/chauffeurs"}>
                  <NavLink to="/chauffeurs">Chauffeurs</NavLink>
                </BlockRender>

                <BlockRender roles={"/vehicles"}>
                  <NavLink to="/vehicles">Vehicles</NavLink>
                </BlockRender>

                <BlockRender roles={'/partners'}>
                  <NavLink to="/partners">Partners</NavLink>
                </BlockRender>

                <BlockRender roles={'/payment-link'}>
                  <NavLink to="/payment-link">Payment Link</NavLink>
                </BlockRender>

                <BlockRender roles={'/companies'}>
                  <NavLink to="/companies">Companies</NavLink>
                </BlockRender>

                <BlockRender roles={'/class'}>
                  <NavLink to="/class">Class</NavLink>
                </BlockRender>

                <BlockRender roles={'/availability'}>
                  <NavLink to="/availability">Availability</NavLink>
                </BlockRender>

                <BlockRender roles={'/city'}>
                  <NavLink to="/city">City</NavLink>
                </BlockRender>
                <BlockRender roles={'/Zone'}>
                  <NavLink to="/zone">Zone</NavLink>
                </BlockRender>
                {/* <BlockRender roles={'/fixed-prices'}>
                  <NavLink to="/fixed-prices">Fixed Prices</NavLink>
                </BlockRender> */}
                {/* <BlockRender roles={'/location-prices'}>
                  <NavLink to="/location-prices">Location Prices</NavLink>
                </BlockRender> */}

                {/* <BlockRender roles={'/pricing'}>
                  <NavLink to="/pricing">Pricing</NavLink>
                </BlockRender> */}
                {priceDropDown.length > 0 ?
                  <BasicMenu
                    list={priceDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => navigate(to)
                    }
                    ))}
                    avatar={false}
                    color="#ffffff"
                  >
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <span>Prices</span>
                      <FaAngleDown />
                    </Stack>
                  </BasicMenu>

                  : null}

                <BlockRender roles={'/campaigns'}>
                  <NavLink to="/campaigns">Campaigns</NavLink>
                </BlockRender>
                {invoiceDropDown.length > 0 ?
                  <BasicMenu
                    list={invoiceDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => navigate(to)
                    }
                    ))}
                    avatar={false}
                    color="#ffffff"
                  >
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <span>Invoices</span>
                      <FaAngleDown />
                    </Stack>
                  </BasicMenu>

                  : null}
                <div style={{ padding: "10px 0" }}>
                  <BasicMenu
                    avatar={true}
                    list={userDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => to === "/logout" ? AuthService.logout() : navigate(to)
                    }
                    ))}
                  >
                    {user.first_name.charAt() + user.last_name.charAt()}
                  </BasicMenu>
                </div>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </header>
  );
}
