import { createSlice } from "@reduxjs/toolkit";
import { mapState } from ".";

const initialState: mapState = {
  startLocation: {},
  endLocation: {},
  startformatted: [],
  endformatted: [],
  startLat: 24.7136,
  startlng: 46.6753,
  endLat: 24.74665,
  endlng: 46.65813,
  show: 0,
  open: false,
  destinationMap: false
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setStartLocation: (state, action) => {
      state.startLocation = action.payload;
    },
    setEndLocation: (state, action) => {
      state.endLocation = action.payload;
    },
    setStartFormatted: (state, action) => {
      state.startformatted = action.payload;
    },
    setEndFormatted: (state, action) => {
      state.endformatted = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload
    },
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setdestinationMap: (state, action) => {
      state.destinationMap = action.payload
    }
  },
});

export const mapReducer = mapSlice.reducer;
export const mapAction = mapSlice.actions;
