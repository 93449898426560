import { config } from "config";
import { LocationPriceState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const default_page_size = config.PAGE_SIZE;

const initialState: LocationPriceState = {
	loading: true,
	locationPrices: [],
	locationPrice: null,
	refresh: 0,
	refreshLoader: false,
	current_filters: {},
	filters: { page: 1, page_size: default_page_size },
};

export const locationPriceSlice = createSlice({
	name: "locationPrice",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addLocationPrice: (state, action) => {
			state.locationPrices.unshift(action.payload);
		},
		updateLocationPrice: (state, action) => {
			const { id, locationPrice } = action.payload;
			state.locationPrices.every(({ _id }, i) => {
				if (id === _id) {
					state.locationPrices[i] = locationPrice;
					return false;
				}
				return true;
			});
		},
		setLocationPrice: (state, action) => {
			state.locationPrice = action.payload;
		},
		setLocationPrices: (state, action) => {
			state.locationPrices = action.payload;
		},
		setPage: (state, action: PayloadAction<number>) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters.page = action.payload;
		},
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state) => {
			state.filters.page = 1;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
			state.refreshLoader = true;
		},
		setCurrentFilters: (state, action) => {
			state.current_filters = action.payload;
		},
		resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
	},
});

const locationPriceReducer = locationPriceSlice.reducer;

export const locationPriceActions = locationPriceSlice.actions;
export default locationPriceReducer;
